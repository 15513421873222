export const LocationType: { region: string; prefectures: string[] }[] = [
  {
    region: '北海道',
    prefectures: ['北海道'],
  },
  {
    region: '東北',
    prefectures: ['青森県', '秋田県', '岩手県', '山形県', '宮城県', '福島県'],
  },
  {
    region: '関東',
    prefectures: ['東京都', '神奈川県', '千葉県', '埼玉県', '群馬県', '栃木県', '茨城県'],
  },
  {
    region: '北陸・甲信越',
    prefectures: ['新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県'],
  },
  {
    region: '東海',

    prefectures: ['静岡県', '愛知県', '岐阜県', '三重県'],
  },
  {
    region: '関西',
    prefectures: ['大阪府', '京都府', '兵庫県', '滋賀県', '奈良県', '和歌山県'],
  },
  {
    region: '中国',
    prefectures: ['広島県', '岡山県', '山口県', '島根県', '鳥取県'],
  },
  {
    region: '四国',
    prefectures: ['徳島県', '香川県', '愛媛県', '高知県'],
  },
  {
    region: '九州・沖縄',
    prefectures: ['福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'],
  },
]

export const ConstantLocationType = [
  {
    area: '北海道・東北',
    prefecture: [
      {
        name: '北海道',
        detail: [
          '札幌市',
          '旭川・富良野・士別',
          '函館・松前・檜山',
          '函館',
          '小樽・ニセコ・積丹',
          '千歳・石狩・夕張・深川',
          '洞爺・苫小牧・室蘭・えりも',
          '網走・知床斜里・北見・紋別',
          '帯広・十勝',
          '釧路・根室・阿寒・摩周・知床羅臼',
        ],
      },
      {
        name: '青森県',
        detail: ['青森・東津軽・八甲田山', '青森市', '弘前・黒石・中津軽・南津軽', '八戸・三沢・十和田'],
      },
      {
        name: '秋田県',
        detail: ['秋田市', '大館・鹿角', '能代・男鹿半島・八郎潟', '田沢湖・角館・大曲', '横手・湯沢'],
      },
      {
        name: '岩手県',
        detail: ['盛岡・雫石', '盛岡', '花巻・北上・遠野', '一関・平泉・奥州', '三陸海岸沿岸', '八幡平・二戸'],
      },
      {
        name: '山形県',
        detail: ['山形・上山', '米沢・白布・南陽', '庄内平野・出羽三山・鳥海山', '新庄・最上', '天童・寒河江・尾花沢'],
      },
      {
        name: '宮城県',
        detail: ['仙台市', '蔵王・白石・名取', '大崎・栗原・登米', '松島・塩釜・南三陸'],
      },
      {
        name: '福島県',
        detail: [
          '福島・二本松',
          '郡山・三春',
          '白河',
          'いわき・相馬',
          '会津若松周辺',
          '喜多方・西会津・只見',
          '南会津',
        ],
      },
    ],
  },
  {
    area: '関東',
    prefecture: [
      {
        name: '東京都',
        detail: [
          '銀座・新橋・有楽町',
          '銀座',
          '有楽町・日比谷',
          '新橋・汐留',
          '東京・日本橋',
          '丸の内・大手町',
          '日本橋・京橋',
          '人形町・小伝馬町',
          '渋谷・恵比寿・代官山',
          '渋谷',
          '恵比寿',
          '代官山',
          '新宿・代々木・大久保',
          '新宿',
          '代々木',
          '大久保・新大久保',
          '池袋～高田馬場・早稲田',
          '池袋',
          '高田馬場',
          '早稲田',
          '原宿・表参道・青山',
          '原宿',
          '表参道',
          '青山一丁目・外苑前',
          '六本木・麻布・広尾',
          '六本木・乃木坂・西麻布',
          '麻布十番',
          '広尾',
          '赤坂・永田町・溜池',
          '赤坂',
          '溜池山王・霞ヶ関',
          '永田町・麹町・半蔵門',
          '四ツ谷・市ヶ谷・飯田橋',
          '四ツ谷',
          '市ヶ谷',
          '飯田橋・神楽坂',
          '秋葉原・神田・水道橋',
          '秋葉原',
          '神田・御茶ノ水',
          '神保町・水道橋',
          '上野・浅草・日暮里',
          '上野・御徒町・湯島',
          '浅草',
          '日暮里',
          '両国・錦糸町・小岩',
          '両国・錦糸町',
          '小岩・新小岩',
          '築地・湾岸・お台場',
          '築地',
          'お台場',
          '浜松町・田町・品川',
          '浜松町・芝公園',
          '田町・三田',
          '品川',
          '大井・蒲田',
          '蒲田',
          '目黒・白金・五反田',
          '目黒',
          '白金',
          '五反田・高輪台',
          '東急沿線',
          '中目黒・祐天寺',
          '自由が丘',
          '京王・小田急沿線',
          '中野～西荻窪',
          '中野',
          '荻窪',
          '吉祥寺・三鷹・武蔵境',
          '吉祥寺',
          '三鷹',
          '西武沿線',
          '板橋・東武沿線',
          '北池袋・板橋駅周辺',
          '大塚・巣鴨・駒込・赤羽',
          '巣鴨・駒込・白山',
          '大塚・護国寺',
          '赤羽',
          '千住・綾瀬・葛飾',
          '小金井・国分寺・国立',
          '小金井',
          '国分寺',
          '調布・府中・狛江',
          '町田・稲城・多摩',
          '町田',
          '西東京市周辺',
          '立川市・八王子市周辺',
          '立川',
          '八王子',
          '福生・青梅周辺',
          '伊豆諸島・小笠原',
        ],
      },
      {
        name: '神奈川県',
        detail: [
          '横浜市',
          '横浜市（市街地）',
          '横浜駅周辺',
          '関内・伊勢佐木町',
          '鎌倉・湘南',
          '鎌倉',
          '川崎市',
          '横須賀・逗子・葉山・三浦',
          '相模原・大和周辺',
          '相模原',
          '海老名・厚木周辺',
          '小田原周辺',
          '箱根・湯河原',
        ],
      },
      {
        name: '千葉県',
        detail: [
          '千葉市',
          '船橋・市川・浦安',
          '船橋',
          '市川',
          '柏・松戸',
          '成田・佐倉・佐原',
          '銚子・九十九里',
          '市原・木更津・富津',
          '勝浦・鴨川・館山',
        ],
      },
      {
        name: '埼玉県',
        detail: [
          'さいたま市',
          '大宮・与野周辺',
          '浦和',
          '川口・越谷・春日部・三郷',
          '和光・新座・志木・川越',
          '上尾・久喜・行田',
          '熊谷・本庄・東松山・寄居',
          '所沢・飯能',
          '秩父・長瀞',
        ],
      },
      {
        name: '群馬県',
        detail: ['前橋・高崎', '桐生・伊勢崎・太田・館林', '沼田・みなかみ・尾瀬・赤城', '渋川・伊香保・草津・吾妻'],
      },
      {
        name: '栃木県',
        detail: ['宇都宮・鹿沼', '宇都宮', '小山・佐野・栃木', '日光・鬼怒川', '真岡・益子・烏山', '那須・塩原'],
      },
      {
        name: '茨城県',
        detail: [
          '水戸・笠間',
          'つくば・土浦・石岡',
          '守谷・取手・牛久・稲敷',
          '鹿島・水郷周辺',
          '北茨城・奥久慈周辺',
          '常総・古河',
        ],
      },
    ],
  },
  {
    area: '中部',
    prefecture: [
      {
        name: '愛知県',
        detail: [
          '名古屋市',
          '栄・矢場町',
          '大須・金山・鶴舞',
          '一宮・稲沢・愛西',
          '犬山・瀬戸・愛知郡',
          '大府・常滑・知多',
          '豊田・岡崎・西尾',
          '田原・豊橋・新城',
        ],
      },
      {
        name: '三重県',
        detail: ['津・松阪', '四日市・鈴鹿・亀山', '伊勢・志摩・鳥羽', '伊賀'],
      },
      {
        name: '岐阜県',
        detail: ['岐阜・大垣・揖斐川', '美濃加茂・郡上', '多治見・恵那・中津川', '高山・飛騨・下呂・白川'],
      },
      {
        name: '静岡県',
        detail: [
          '静岡市（静岡・清水）',
          '浜松・掛川・磐田',
          '浜松',
          '焼津・藤枝・御前崎',
          '富士山周辺',
          '沼津・伊豆半島',
          '沼津・三島',
        ],
      },
      {
        name: '山梨県',
        detail: ['甲府・山梨・笛吹', '北杜・小淵沢・南アルプス', '富士五湖・忍野・富士吉田'],
      },
      {
        name: '長野県',
        detail: [
          '長野・志賀高原・北信濃',
          '松本・美ヶ原・上高地',
          '松本市',
          '軽井沢・佐久',
          '軽井沢',
          '上田・小諸・蓼科・諏訪',
          '安曇野・白馬',
          '南信州',
        ],
      },
      {
        name: '新潟県',
        detail: [
          '新潟・三条・佐渡',
          '新潟市',
          '長岡・柏崎',
          '上越・糸魚川・妙高',
          '魚沼・十日町・湯沢',
          '五泉・新発田・村上',
        ],
      },
      {
        name: '石川県',
        detail: [
          '金沢周辺',
          '金沢',
          '加賀・白山',
          '能登南部',
          '福井県',
          '福井・芦原・永平寺',
          '武生・鯖江周辺',
          '敦賀・若狭周辺',
        ],
      },
      {
        name: '富山県',
        detail: ['富山', '富山市', '黒部・魚津周辺・下新川', '中新川', '高岡・氷見'],
      },
      {
        name: '福井県',
        detail: [],
      },
    ],
  },
  {
    area: '関西',
    prefecture: [
      {
        name: '大阪府',
        detail: [
          '大阪市',
          '大阪市内（キタ）',
          '大阪駅・梅田・新地',
          '北浜・淀屋橋・肥後橋',
          '大阪市内（ミナミ）',
          '心斎橋・南船場・長堀橋',
          '難波・日本橋・道頓堀',
          '天王寺・阿倍野',
          '大阪市内（北東部）',
          '新大阪駅周辺',
          '堺・泉南',
          '堺',
          '豊中・池田・高槻',
          '北河内・東大阪',
          '南河内',
        ],
      },
      {
        name: '京都府',
        detail: [
          '京都市',
          '京都駅周辺',
          '京都駅周辺',
          '御所・二条城・河原町',
          '河原町・木屋町・先斗町',
          '宇治・南山城',
          '亀岡・丹波・福知山',
          '天橋立・丹後半島',
        ],
      },
      {
        name: '兵庫県',
        detail: [
          '神戸市',
          '三宮',
          '元町・ハーバーランド周辺',
          '宝塚・西宮・尼崎',
          '西宮',
          '尼崎',
          '明石・東播磨・北播磨',
          '姫路・中播磨・西播磨',
          '姫路',
          '淡路島',
          '丹波篠山',
          '城崎・山陰海岸・但馬山地',
        ],
      },
      {
        name: '滋賀県',
        detail: ['大津市', '甲賀・湖南', '湖東', '湖北'],
      },
      {
        name: '奈良県',
        detail: ['奈良市', '橿原・御所・飛鳥', '天理・宇陀・山の辺', '五條・吉野山・大峰'],
      },
      {
        name: '和歌山県',
        detail: ['和歌山・高野山下', '海南・有田路', '御坊・みなべ', '白浜・田辺', '新宮・串本・勝浦'],
      },
    ],
  },
  {
    area: '中国・四国',
    prefecture: [
      {
        name: '広島県',
        detail: ['広島市', '福山市', '尾道市', '東広島市', '呉・竹原・三原', '宮島・廿日市・大竹', '三次・庄原'],
      },
      {
        name: '岡山県',
        detail: [
          '岡山・吉備',
          '岡山市',
          '倉敷・笠岡・井原',
          '倉敷',
          '高梁・蒜山高原',
          '津山・奥津・美作',
          '牛窓・備前',
        ],
      },
      {
        name: '山口県',
        detail: ['山口・防府', '下関・角島', '萩・長門', '宇部・小野田', '周南', '岩国・柳井'],
      },
      {
        name: '島根県',
        detail: ['松江・安来周辺', '出雲周辺・奥出雲'],
      },
      {
        name: '鳥取県',
        detail: ['鳥取市・鳥取県東部', '倉吉・三朝周辺', '米子・境港・大山周辺'],
      },
      {
        name: '徳島県',
        detail: ['徳島・鳴門', '阿波・美馬'],
      },
      {
        name: '香川県',
        detail: ['高松周辺', '坂出・丸亀・塩飽諸島', '観音寺・琴平周辺', 'さぬき・東かがわ', '小豆島'],
      },
      {
        name: '愛媛県',
        detail: ['松山・伊予', '松山', '今治・新居浜・しまなみ海道', '宇和島・愛南'],
      },
      {
        name: '高知県',
        detail: ['高知・南国・嶺北', '土佐・横浪', '四万十川流域・足摺岬'],
      },
    ],
  },
  {
    area: '九州・沖縄',
    prefecture: [
      {
        name: '福岡県',
        detail: [
          '福岡市',
          '福岡市内（市街地）',
          '博多',
          '天神',
          '太宰府・宗像・糟屋郡',
          '北九州市',
          '北九州市周辺',
          '筑豊',
          '久留米・筑後',
          '糸島',
        ],
      },
      {
        name: '佐賀県',
        detail: ['佐賀・鳥栖', '唐津・伊万里・有田', '武雄・多久・小城'],
      },
      {
        name: '長崎県',
        detail: ['長崎・西彼杵', '佐世保・平戸', '雲仙・島原', '諫早・大村', '五島・壱岐・対馬'],
      },
      {
        name: '熊本県',
        detail: ['熊本周辺・宇土・山都', '熊本市', '阿蘇', '菊池・山鹿・玉名', '八代・水俣・人吉', '天草'],
      },
      {
        name: '大分県',
        detail: ['大分・竹田・臼杵', '湯布院・別府', '国東半島', '中津・宇佐・日田'],
      },
      {
        name: '宮崎県',
        detail: ['宮崎市周辺', '日南・串間', '都城・えびの', '延岡・高千穂'],
      },
      {
        name: '鹿児島県',
        detail: ['鹿児島・南薩摩', '薩摩川内・出水・北薩摩', '伊佐・霧島', '大隅半島'],
      },
      {
        name: '沖縄県',
        detail: ['那覇市', '本島北部', '本島中部', '本島南部', '石垣島・宮古島・先島諸島', '久米島・慶良間群島'],
      },
    ],
  },
]

export const jpKantouAreas = [
  '渋谷',
  '青山・表参道・原宿',
  '恵比寿・広尾・六本木・麻布・赤坂',
  '代官山・中目黒・自由が丘・武蔵小杉・学大',
  '三軒茶屋・二子玉川・溝の口・青葉台',
  '品川・目黒・五反田・田町',
  '新宿・高田馬場・代々木',
  '中野・高円寺・阿佐ヶ谷',
  '下北沢・成城学園・向ヶ丘遊園・新百合ヶ丘',
  '池袋・目白',
  '銀座・有楽町・新橋・丸の内・日本橋',
  '上野・神田・北千住・亀有・青砥・町屋',
  '吉祥寺・荻窪・三鷹・国分寺・久我山',
  '八王子・立川・国立・多摩・日野・福生・秋川',
  '両国・錦糸町・小岩・森下・瑞江',
  '門前仲町・勝どき・月島・豊洲',
  '御茶ノ水・四ツ谷・千駄木・茗荷谷',
  '明大前・千歳烏山・調布・府中',
  '赤羽・板橋・王子・巣鴨',
  '鷺ノ宮・田無・東村山・拝島',
  '町田・相模大野・海老名・本厚木・橋本',
  '横浜・関内・元町・上大岡・白楽',
  '大井町・大森・蒲田・川崎・鶴見',
  '横須賀・小田原',
  'センター南・二俣川・戸塚・杉田・金沢文庫',
  '湘南・鎌倉・逗子',
  '大宮・浦和・川口・岩槻',
  '大山・成増・志木・川越・東松山',
  '練馬・ひばりヶ丘・所沢・飯能・狭山',
  '西新井・草加・越谷・春日部・久喜',
  '上尾・熊谷・本庄',
  '東大宮・古河・小山',
  '流山・三郷・野田',
  '千葉・稲毛・幕張・鎌取・都賀',
  '船橋・津田沼・本八幡・浦安・市川',
  '柏・松戸・我孫子',
  '市原・木更津・茂原・勝浦・東金・銚子',
  '八千代・佐倉・鎌ヶ谷・成田',
  '宇都宮・栃木',
  '取手・土浦・つくば・鹿嶋',
  '前橋・高崎・伊勢崎・太田・群馬',
  '山梨',
  '水戸・ひたちなか・日立・茨城',
]
